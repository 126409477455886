@import '../../../styles/Colors/colors';

.admin-maps {
  .maps-header {
    text-align: left;
    padding: 0 50px 24px;
    border-bottom: 1px solid $primary-border;
  }

  .admin-maps-container {
    padding: 20px;
  }

  .admin-maps-info {
    text-align: left;
    min-height: 82px;

    h3 {
      margin-bottom: 0;
    }
  }

  .admin-maps-info__list {
    display: flex;
    flex-direction: column;
    max-height: 132px;
    overflow: scroll;
    background-color: #f2f4f7;

    // chrome, safari, opera
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .admin-maps-info__msg {
    padding-top: 10px;
    font-style: italic;
  }

  .admin-maps-info__order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    p span {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
