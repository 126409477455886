@import '../../styles/Colors/colors';

.help-page-container {
  margin-top: 64px;
  display: flex !important;
  flex-direction: column !important;
  text-align: left;
  padding: 0 40px 150px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding: 0 18px 150px;
  }

  .help-header {
    display: flex;
    justify-content: space-between;
    background-color: $primary-background;
    padding: 50px 5px 20px;
    width: 100%;
    border: none;
  }

  .separation-header {
    margin-bottom: 20px;
    margin-bottom: 20px;
  }

  .help-accordion,
  .help-accordion::before {
    box-shadow: none;
    background-color: transparent;
  }
  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding: 0 10px;
    a {
      font-weight: 700;
      text-decoration: underline;
    }
    h2 {
      font-size: 25px;
    }
  }
  .MuiAccordion-root.Mui-expanded {
    margin: 0 0 16px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
  .inline-link {
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
  .terms-privacy {
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
  }
  .bullet-seperator {
    padding: 0 8px;
  }
  .MuiTypography-h2 {
    font-size: 25px important;
  }
}
.bottom-button-wrapper {
  width: 100%;
  padding: 20px 51px;
  position: fixed;
  bottom: 80px;
  z-index: 100;
  background-color: $primary-background;

  @media screen and (max-width: 768px) {
    padding: 20px 28px;
  }

  .sign-out-button-mobile {
    width: 100%;
  }
}
