@import 'src/styles/Typography/typography';

.password-requirements {
  font-family: $font-IBM-Plex-Sans-Regular;
  text-align: left;

  .list-bullet {
    display: inline-block;
    width: 20px;
  }

  .requirements-list {
    padding-left: 0;

    li {
      align-items: center;
      display: flex;
      line-height: 27px;
    }

    span {
      margin-left: 5px;
    }

    svg {
      height: 21px;
    }
  }

  .requirement-text {
    margin-left: 5px;
  }

  .error-text {
    color: $error-red;
  }
}
