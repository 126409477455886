@import '../../styles/Colors/colors';

.report-modal {
  .search-box {
    display: flex;
    border: none !important;

    .rbt-input-main {
      width: 100%;
      background-color: #f2f4f7;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-radius: 0;
      padding-left: 12px;
      padding-top: 25px;
      height: 55px;
      align-items: center;
      align-self: center;
      outline: none;

      @media screen and (max-width: 600px) {
        width: 300px;
      }
    }

    .rbt-input-hint {
      padding-top: 22px !important;
    }

    .search-orders-field {
      background-color: #f2f4f7;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      margin-top: 10px;
      border-radius: 0;
      outline: none !important;
      height: 55px;
      align-items: center;
      align-self: center;

      img {
        position: absolute;
        right: 12px;
        bottom: 17px;
        height: 6px;
        width: 10px;
        pointer-events: none;
      }

      .info-text {
        position: absolute;
        top: 10px;
        left: 12px;
        color: $secondary-text;
        font-size: 12px;
        line-height: 16px;
      }
      .results-item {
        display: flex;
        margin-top: 10px;
        padding: 5px;
        z-index: 3;
        color: $primary-text;

        svg {
          margin: 0;
          color: $secondary-text;
          opacity: 0.54;
        }
        &:hover {
          background-color: #f5f5f5;
        }

        &.disabled {
          svg {
            fill: #c8d6e5;
          }
        }
      }

      input {
        &:focus {
          padding-bottom: 0;
          border-bottom: 2px solid #2875c3 !important;
        }
      }

      input::placeholder {
        color: $secondary-text;
        font-size: 15px;
        line-height: 24px;
        text-align: left;
      }

      input:focus::placeholder {
        color: transparent !important;
      }

      .dropdown-menu {
        display: none;
      }

      .dropdown-item {
        display: none;
      }

      .results-menu {
        display: flex !important;
        flex-direction: column;
        background-color: $white;
        z-index: 3;
        overflow: auto;
        max-height: 350px;
        min-height: 80px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        position: absolute;
        width: 100%;

        a {
          display: flex;
        }

        .event-name {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          font-size: 15px;
          line-height: 24px;

          i {
            color: #8395a7;
          }

          span {
            &:first-child {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 425px;
            }
            &:last-child {
              color: #8395a7;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }

      .rbt-aux {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 0;
      }
    }
  }
}
