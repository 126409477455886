@media (max-width: 992px) {
  .calendar-picker-popup--rangepicker.mobile:not(.with-time) {
    .ant-picker-panel:last-child .ant-picker-date-panel {
      display: none !important;
    }

    .ant-picker-panel:first-child,
    .ant-picker-date-panel,
    .ant-picker-header-next-btn,
    .ant-picker-panel:first-child,
    .ant-picker-date-panel,
    .ant-picker-header-super-next-btn {
      visibility: initial !important;
    }

    .ant-picker-panel.ant-picker-range-wrapper {
      min-width: 288px !important;
    }

    .ant-picker-presets {
      display: none !important;
    }
  }

  .calendar-picker-popup--rangepicker.mobile.with-time {
    .ant-picker-panel-container {
      .ant-picker-presets {
        min-height: 25rem !important;
      }
      @media (max-width: 800px) {
        overflow: scroll !important;
        height: 400px;
        .ant-picker-panel-layout {
          flex-direction: column !important;

          .ant-picker-presets {
            max-width: 100% !important;
            min-height: 10rem !important;
          }

          .ant-picker-panels,
          .ant-picker-datetime-panel {
            flex-direction: column !important;
          }
        }
      }
    }
  }
}


