@import '../../styles/Colors/colors';

.custom-question-container {
  .right-col {
    h4 {
      margin-bottom: 20px;
    }
    .MuiSelect-root {
      padding-top: 26px !important;
    }
  }

  .question-option {
    width: 100%;
    display: flex;

    .MuiTypography-root {
      width: 100%;
    }

    .option-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiSvgIcon-root {
        position: relative;
        right: -1%;
        display: none;

        &:hover {
          display: block;
        }
      }
    }
  }

  .possible-answers {
    margin-left: 20px;
    width: 205%;

    .possible-answer {
      &.empty-answer {
        border-bottom: 1px solid $error-red;
      }
      &:hover {
        border-bottom: 2px solid $primary-border;
        svg {
          display: block;
        }
      }
    }

    .other-parent {
      &:hover {
        cursor: pointer !important;
      }
    }

    .other-radio {
      color: $link-text;
    }

    .MuiFormControl-root {
      background: none !important;
      height: 35px !important;
      .MuiInput-underline:before {
        border-bottom: none;
      }
      .MuiInput-underline:after {
        border-bottom: none;
      }
    }
  }

  .footer {
    padding-top: 8px;
    margin-bottom: -12px;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $primary-border;

    .MuiSvgIcon-root {
      color: $secondary-text;
    }
    .separator {
      border-right: 1px solid $primary-border;
      height: 24px;
      margin: 0 20px 0 7px;
    }
  }

  &.mobile {
    .card-item {
      padding: 16px;
    }

    .footer {
      margin-bottom: 0;
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-start;

      .separator {
        margin: 0 8px 0 7px;
      }

      .add-question-button {
        position: absolute;
        bottom: -70px;
        right: 0;
      }
    }
  }
}
