$primary-text: #11181f;
$primary-border: #c8d6e5;
$primary-background: #f2f4f7;
$primary-button: #10ac84;
$primary-warning: #ff9f43;

$filled-background: #e8f0fe;

$secondary-text: #8395a7;
$secondary-button: #222f3e;
$secondary-cal-dropdown: #4cc1a3;
$secondary-cal-disabled: #cacccd;

$text-accent: #0fac84;

$side-nav-color: #576574;
$link-text: #2875c3;

$white: #ffffff;
$error-red: #ee5253;

$checkmark: #2e86de;
