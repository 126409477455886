@import 'src/styles/Colors/colors';

$font-IBM-Plex-Sans-Regular: IBMPlexSans-Regular, Roboto, Helvetica, Arial, sans-serif;
$font-IBM-Plex-Sans-Bold: IBMPlexSans-Bold, Roboto, Helvetica, Arial, sans-serif;

@mixin button-text {
  font-family: $font-IBM-Plex-Sans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.704px;
  text-transform: uppercase;
  color: $white;
}

@mixin p-text {
  font-family: $font-IBM-Plex-Sans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: $primary-text;
}

@mixin heading1 {
  // TODO: discover what these styles are and where we use h1
}

@mixin heading2 {
  font-family: $font-IBM-Plex-Sans-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 1.1px;
  color: $primary-text;
  text-transform: uppercase;
}
