@import '../../../../../src/styles/Colors/colors';
.basic-info__deferred,
.basic-info__protected-event,
.basic-info__password,
.basic-info__hide-event {
  margin-top: 20px;

  .basic-info__deffered--header,
  .basic-info__protected-event--header,
  .basic-info__password--header,
  .basic-info__hide-event--header {
    display: flex;
    align-items: center;
  }

  .deffered-tip {
    font-size: 8px;
  }

  .deferred-description {
    line-height: 25px;
  }

  .deferred-selection-notice {
    background: #f7e569;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 11px;
    display: flex;
  }

  .deferred-enable,
  .protected-event-enable {
    .MuiSwitch-colorPrimary.Mui-checked {
      color: $white;
    }
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
      background-color: $primary-button;
      opacity: 1;
    }
  }

  .deferred__access-label--container {
    display: flex;
    align-items: center;
  }

  .deferred__access-label--label {
    margin-right: 5px;
  }
}
