.booking-col {
  padding-left: 50px;
}

.date-picker-container > div {
  display: flex !important;
}

.date-picker-container .calendar-picker {
  width: 100%;
  height: 57px;
}

.booking-time {
  display: flex;
  margin-top: 20px;

  .open-time-field {
    margin-right: 10px;
    margin-bottom: 0 !important;
  }
  .close-time-field {
    margin-left: 10px;
    margin-bottom: 0 !important;
  }
}

.protected-event-heading {
  display: flex;
  flex-direction: row;
  align-items: center;

  h4 {
    margin-bottom: 0 !important;
  }
}
