@import '../../../styles/Colors/colors';

.create-rvlot-container {
  max-width: 1200px;
  margin: 100px auto;

  .MuiInputBase-root {
    width: 100%;
    height: 56px;
  }
  .state-select {
    margin-top: 20px;
    background-color: $primary-background;
    height: 55px;
    padding-top: 13px;

    &.filled {
      background-color: $filled-background;
    }
  }
  .MuiGrid-container {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  h4 {
    margin-top: 0;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin: 40px 0 20px;

    p {
      margin: 2px;
      margin-left: 10px;
    }

    p:first-child {
      font-size: 16px;
    }

    p:last-child {
      color: #adadad;
    }
  }

  .flex-button-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 42px 0 0;

    @media screen and (max-width: 601px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0 0;
    }

    .form-button {
      line-height: 0;
      width: 100px !important;
      margin-left: 20px;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;
}

.table td,
.table th {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}

.table th {
  background-color: #777;
  color: #ffffff;
}

.table tbody tr:nth-child(even) {
  background-color: #eee;
}

/*responsive*/

@media (max-width: 500px) {
  .table thead {
    display: none;
  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr {
    margin-bottom: 15px;
  }
  .table td {
    padding-left: 50%;
    text-align: left;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}

.modal-card {
  width: 399px;
  margin: calc(30vh - 16px) auto auto;
  padding: 30px;

  .header-container {
    margin: 0;
    display: flex;
    padding: 20px 20px 10px 20px;
    text-transform: uppercase;

    h4 {
      padding-top: 2px;
    }
  }

  .flex-button-wrapper {
    margin: 20px 0;
  }
}
