@import 'src/styles/Colors/colors';

.input-password-wrapper {
  display: flex;
  width: 100%;

  .input-password {
    width: 100%;
    font-size: 15px;

    .input-password-adornment {
      background-color: transparent;
    }

    .show-hide-password {
      color: $link-text;
      width: 50px;
    }
  }
}
