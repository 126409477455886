.svg-map {
  width: 100%;
  height: 100%;
}

.svg-map svg {
  width: 100%;
  height: 100%;
}

.react-transform-wrapper {
  width: 100%;
  height: 100%;
}


.mapLoader {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  border: 1px solid #d6d6d6;

  svg {
    #interactive {
      rect, path {
        cursor: pointer;
      }

      rect:hover, path:hover {
        stroke: #10AC84;
        fill: #F5F5F5;
      }

      rect.selected, path.selected {
        stroke: #D5D7DB;
        fill: #10AC84;
      }

      rect.disabled, path.disabled {
        stroke: #D5D7DB;
        fill: #B9C7D7;
        cursor: no-drop;
      }

      rect.locked, path.locked {
        stroke: #D5D7DB;
        fill: #C8D6E5;
      }

      rect.locked:hover, path.locked:hover {
        stroke: #D5D7DB;
        fill: #8395A7;
      }
    }

    #Labels,
    #labels,
    #Layout,
    #layout {
      * {
        pointer-events: none;
      }
    }
  }

  .tools {
    z-index: 2;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    bottom: 0;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.16)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.26));

    button {
      width: 35px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #C8D6E5;
      color: #11181F;
      font-size: 24px;

      &:first-of-type {
        border-radius: 5px 5px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .actions {
    margin-top: 50px;

    .button-lock {
      width: 200px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
