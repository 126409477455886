.sign-up-hero {
  background-image: url('../../../assets/img/horse-in-stall.jpeg');
  background-color: gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 0 0 2000px rgba(0.07, 0.09, 0.12, 0.5);
  color: transparent;
  height: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  .open-stalls-logo {
    display: block;
    width: 235px;
    padding: 25px 0 10px 0;
    height: 50px;
    margin: 0 auto;
  }

  .rodeo-logo {
    width: 254px;
    height: 37px;
    margin-bottom: 8px;
  }
  p {
    font-family: IBMPlexSans-Regular, Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    width: 60%;
    color: #fff;
    margin: 0 auto 25px;
  }

  @media screen and (min-width: 600px) {
    background-image: url('../../../assets/img/horse-in-stall.jpeg');
    background-color: gray;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 40%;
    box-shadow: inset 0 0 0 2000px rgba(0.07, 0.09, 0.12, 0.5);
  }

  @media screen and (min-width: 960px) {
    background-image: url('../../../assets/img/horse-in-stall.jpeg');
    background-color: gray;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60% center;
    color: transparent;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;

    .rodeo-logo {
      width: 342px;
      height: 49px;
      margin-bottom: 25px;
    }

    .open-stalls-logo {
      width: 402px;
      height: 93px;
    }

    p {
      width: 40%;
      max-width: 300px;
    }
  }
}
